import { extendTheme } from '@chakra-ui/react';
import { FONT_FAMILY } from '@/constants/fonts';
import {
  BG_SECONDARY,
  BG_PRIMARY,
  BG_SECONDARY_DARK,
  BG_SECONDARY_TRANSPARENT,
  BG_ALTERNATIVE,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_PRIMARY_DARK,
} from '@/constants/colors';

/**
 * override chackra-ui for personalize purpose
 * see the docs: https://chakra-ui.com/guides/using-fonts
 */
const theme = {
  fonts: {
    heading: FONT_FAMILY.heading,
    body: FONT_FAMILY.body,
    fantasy: FONT_FAMILY.fantasy,
    jost: `'Jost', serif`,
  },
  colors: {
    bgPrimary: 'rgba(241,238,233,1)',
bgSecondary: 'rgba(81,82,64,1)',
bgAlternative: 'rgba(81,82,64,1)',
bgOpacity: '#053A28',
mainColorText: '#000',
secondaryColorText: '#fff',
mainColorTextDark: 'rgba(81,82,64,1)',

    secondaryColorText: TEXT_SECONDARY,
    transparent: 'transparent',
    theme: {
      bgPrimaryText: TEXT_PRIMARY,
      bgPrimary: BG_PRIMARY,
      bgSecondary: BG_SECONDARY,
      bgSecondaryDark: BG_SECONDARY_DARK,
      bgSecondaryTransparent: BG_SECONDARY_TRANSPARENT,
    },
    button: {
      secondary: {
        500: BG_SECONDARY,
      },
      secondaryDark: {
        500: BG_SECONDARY_DARK,
      },
    },
  },
  shadows: {
    outline: BG_SECONDARY_DARK,
  },
};

export default extendTheme(theme);
