export const BG_PRIMARY = '#F1EEE9';
export const BG_SECONDARY = '#515240';
export const BG_SECONDARY_LIGHT = '#dca74f';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = 'rgb(81	82	64 / 29%)';
// this colors used for green background
export const BG_ALTERNATIVE = '#515240';
export const TEXT_PRIMARY_LIGHT = '#cf9c2f';
export const TEXT_PRIMARY = '#515240';
export const TEXT_PRIMARY_DARK = '#000';
export const TEXT_SECONDARY = '#fff';

// Exclusive to template-naomirezky
export const FLOWER_COLOR = '#000';
